import React, { useState, useEffect, useRef } from "react";
import noProfile from "../assets/images/No profile.jpg";
import EmojiPicker from "emoji-picker-react";
import sound from "../assets/audio/Message Sent.mp3";
import OnlineIcon from "../assets/images/site-alt.svg";

const MessageInput = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentMember, setCurrentMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [onlineUserCount, setOnlineUserCount] = useState(0);
  const audio = new Audio(sound);

  const messagesEndRef = useRef(null);
  const droneRef = useRef(null);

  useEffect(() => {
    const users = [
      "Gerrard Butler",
      "Megan Fox",
      "Leonardo DiCaprio",
      "Tom Hanks",
      "Johnny Depp",
      "Margot Robbie",
      "Zendaya",
      "Gal Gadot",
      "Angelina Jolie",
      "Jason Statham",
      "Brad Pitt",
      "Tom Cruise",
      "Morgan Freeman",
      "Bruce Willis",
      "Salma Hayek",
      "Vin Diesel",
      "Emma Watson",
      "Scarlett Johansson",
      "Chris Hemsworth",
      "Cameron Diaz",
      "Morgan Freeman",
      "Robert Downey Jr.",
      "Dwayne Johnson",
      "Julia Roberts",
    ];

    const randomUser = users[Math.floor(Math.random() * users.length)];
    /* *****CONNECT TO SCALEDRONE***** */
    droneRef.current = new window.Scaledrone("HsvLLDa4gx2pSA4C", {
      data: {
        name: randomUser,
      },
    });

    const drone = droneRef.current;

    drone.on("open", (error) => {
      if (error) {
        console.error(error);
        return;
      }

      /* *****SUBSCRIBE TO ROOM***** */
      const room = drone.subscribe("Teo-ChatApp");
      room.on("open", (error) => {
        if (error) {
          console.error(error);
        } else {
          setCurrentMember(drone.clientId);
          /* console.log(`ID korisnika sobe: ${drone.clientId}`); */
        }
      });

      room.on("message", (message) => {
        /* console.log("Received message:", message); */
        setMessages((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
          messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }, 0);
      });

      drone.on("members", (members) => {
        setMembers(members.clients);
      });

      drone.on("error", (error) => console.error(error));
    });

    return () => {
      droneRef.current.close();
    };
  }, []);
  /* *****SHOW HOW MANY USERS ARE ONLINE***** */
  useEffect(() => {
    setOnlineUserCount(members.length);
    updateCurrentUser();
  }, [members]);

  const updateCurrentUser = () => {
    const users = [
      "Gerrard Butler",
      "Megan Fox",
      "Leonardo DiCaprio",
      "Tom Hanks",
      "Johnny Depp",
      "Margot Robbie",
      "Zendaya",
      "Gal Gadot",
      "Angelina Jolie",
      "Jason Statham",
      "Brad Pitt",
      "Tom Cruise",
      "Morgan Freeman",
      "Bruce Willis",
      "Salma Hayek",
      "Vin Diesel",
      "Emma Watson",
      "Scarlett Johansson",
      "Chris Hemsworth",
      "Cameron Diaz",
      "Morgan Freeman",
      "Robert Downey Jr.",
      "Dwayne Johnson",
      "Julia Roberts",
    ];

    const randomUser = users[Math.floor(Math.random() * users.length)];
    setCurrentUser(randomUser);
  };

  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  /* *****SEND MESSAGE INFO***** */
  const sendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {
        text: inputValue,
        time: new Date(),
        user: currentUser,
        userID: currentMember,
      };

      setInputValue("");
      audio.play();

      droneRef.current.publish({
        room: "Teo-ChatApp",
        message: {
          ...newMessage,
        },
      });
    }
  };
  /* SEND MESSAGE ON KEYPRESS */
  const sendOnKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue.trim() !== "") {
        sendMessage();
        audio.play();
      }
    }
  };
  /* SHOW EMOJI BOX ON CLICK */
  const emojiButton = () => {
    setShowEmoji(!showEmoji);
  };

  const showEmojiBox = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setInputValue((prevValue) => prevValue + emoji);
    emojiButton();
  };

  return (
    <div className="chat-app">
      {/* *****SHOW ONLINE USERS***** */}
      <div className="online-users">
        <img src={OnlineIcon} className="online-icon" alt="online-icon"></img>
        <h4>/ {onlineUserCount}</h4>
      </div>

      {/* *****MESSAGES LIST***** */}
      <div className="messages">
        <ul className="list">
          {messages.map((message, index) => (
            <li
              key={index}
              className={`${
                message.data?.userID === currentMember
                  ? "textMessage"
                  : "sended-message"
              } message-item`}
            >
              {/*    {console.log(`ID korisnika iz poruke: ${message.data?.userID}`)} */}
              <div className="message-box">
                <h2 className="user">{message.data?.user}</h2>
                <img
                  className="message-profile"
                  src={noProfile}
                  alt="No profile"
                />
              </div>
              <p className="message">
                {message.data?.text}
                <span className="date">
                  {new Date(message.data?.time).toLocaleTimeString(
                    "hr-HR",
                    options
                  )}
                </span>
              </p>
            </li>
          ))}
          <div ref={messagesEndRef} />
        </ul>
      </div>
      {/* *****MESSAGE INPUT & EMOJIS & KEYPRESS***** */}
      <div className="chat-box">
        <button className="chat-btn" onClick={emojiButton}>
          <i className="fi fi-rr-smile"></i>
        </button>
        <input
          type="text"
          id="inputField"
          placeholder="Send Message..."
          required
          value={inputValue}
          onKeyDown={sendOnKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button className="chat-btn" onClick={sendMessage}>
          <i className="fi fi-rr-paper-plane"></i>
        </button>
      </div>
      {showEmoji && (
        <div className="emoji-picker-container">
          <EmojiPicker onEmojiClick={showEmojiBox} />
        </div>
      )}
    </div>
  );
};

export default MessageInput;
