import React, { useState, useEffect } from "react";
import MessageInput from "./Input";
import LogoBackground from "./LogoBackground";

export default function Navigation() {
  // Use state to manage dark mode
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "set"
  );

  useEffect(() => {
    /* *****Update localStorage when darkMode changes***** */
    localStorage.setItem("darkMode", darkMode ? "set" : "unset");
  }, [darkMode]);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };
  /*  */

  return (
    <main className={`${darkMode ? "darkmode" : ""}`}>
      <div className="desktop-bg">
        <LogoBackground />
      </div>
      {/*  */}

      <div className="section-text-app">
        <div>
          <div className="chat-navigation">
            <h1>
              <i className="icons fi fi-rr-comments"></i>Wazapp
            </h1>
          </div>
          <button onClick={toggleDarkMode} className="night-mode">
            {darkMode ? "🔅" : "🌙"}
          </button>
        </div>
        <MessageInput />
      </div>
    </main>
  );
}
