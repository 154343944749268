import "./assets/css/App.scss";
import { LoadingScreen, Navigation } from "./Components";

export default function App() {
  return (
    <div className="App">
      <LoadingScreen />
      <Navigation />
    </div>
  );
}
