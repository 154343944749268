import background from "./../assets/images/background.jpeg";

export default function LogoBackground() {
  /* *****SHOW DESKTOP BG WHEN USING APP ON DESKTOP***** */
  return (
    <div class="desktop-bg">
      <img src={background} alt="" />
      <h1 class="main-title">
        <i class="icons fi fi-rr-comments"></i>Wazapp
      </h1>

      <div class="bottom-links">
        <a href="https://www.example.com">Term of Use</a>
        <a href="https://www.example.com">Services Agreement</a>
        <a href="https://www.example.com">Privacy Policy</a>
        <a href="https://www.example.com">&copy; 2023 </a>
      </div>
    </div>
  );
}
